@import "global";

html, body {
    font-family: 'Roboto', 'Helvetica', sans-serif;
    background-color: #000;
    color: #fff;
}

* {
    box-sizing: border-box;
}

a {
    color: #febd0b;
}

img {
    height: auto;
    max-width: 100%;
}

button {
    -webkit-appearance: none;
}

.header-top {
    @media (min-width: 1025px) {
        margin-left: 240px;
        width: calc(100% - 240px);
    }
    .mdl-textfield {
        //padding:18px 0 19px;
        padding: 0;
        line-height: 64px;
        @media (max-width: 1025px) {
            //padding:14px 0 15px;
            line-height: 56px;
        }
    }
    .search {
        .mdl-button--icon {
            position: static;
            width: auto;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            line-height: 27px;
            height: 27px;
            span {
                font-size: 14px;
                line-height: 27px;
                display: inline-block;
                vertical-align: top;
                font-weight: 500;
                @media (max-width: 479px) {
                    display: none;
                }
            }
            .material-icons {
                position: static;
                transform: none;
                margin-top: -2px;
                margin-left: -2px;
                line-height: 27px;
                vertical-align: middle;
            }
        }
        .mdl-textfield__input {
            border-color: #000;
            line-height: 18px;
        }
        .mdl-textfield__label {

            &:after {
                background-color: #000;
            }
        }

        .mdl-textfield__expandable-holder {
            margin-left: 5px;
            .material-icons {
                display: none;
            }
        }
        &.is-dirty {
            .mdl-textfield__expandable-holder {
                padding-right: 24px;
                .material-icons {
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    top: 4px;
                    z-index: 4;
                    cursor: pointer;
                }
            }
        }
    }
    .downloads, .login {
        align-self: flex-start;
        min-width: 1px;
        a {
            display: inline-block;
            line-height: 27px;
            height: 27px;
            text-decoration: none;
            vertical-align: middle;
            position: static;

            .material-icons {
                line-height: 27px;
                margin-top: -2px;
            }
            span {
                display: inline-block;
                line-height: 27px;
                vertical-align: top;
                @media (max-width: 479px) {
                    display: none;
                }
            }
        }
    }
    .logo {
        img {
            height: 24px;
        }
        @media (min-width: 1025px) {
            display: none;
        }
    }
    &.big {
        min-height: 110px;
        @media (max-width: 1024px) {
            min-height: 70px;
        }
        .header-row {
            height: 110px;
            @media (max-width: 1024px) {
                height: 70px;
            }
        }
    }
    .title {
        align-self: flex-end;
        h3 {
            font-weight: 700;
            margin: 0;
            @media (max-width: 1024px) {
                font-size: 28px;
            }
        }
    }
    .mdl-layout__drawer-button {
        color: #000;
    }
    .mdl-button {
        &:active, &:hover, &:active .material-icons, &:hover .material-icons {
            background-color: inherit;
        }
    }
    ul.mdl-list.menu-list {
        line-height: 20px;
        li {
            display: inline-block;
            padding-right: 20px;
            a {
                color: #000;
                text-decoration: none;
                line-height: 20px;
                text-transform: uppercase;

            }
        }
        @media (max-width: 1024px) {
            display: none;
        }
    }
}

.drawer {
    background-color: #030303;
    border: none;
    .drawer-header {
        padding: 10px;
        text-align: center;
        .logo {
            max-width: 90%;
            margin: auto;
        }
        .banner-1 {
            margin-top: 56px;
            .teaser {
                width: 160px;
                height: 600px;
                margin: 40px auto;
                @media (max-width: 1024px) {
                    display: none;
                }
                img {
                    max-width: 100%;
                    height: auto;
                    margin: 0;
                }
            }
        }
        .banner-3 {
            width: 160px;
            height: 600px;
            margin: 40px auto;
            @media (max-width: 1024px) {
                display: none;
            }
            img {
                max-width: 100%;
                height: auto;
                margin: 0;
            }
        }

    }
    .left-navigation {
        @media (min-width: 1025px) {
            display: none;
        }

        .mdl-list {
            list-style: none;
            padding: 0;
            li {
                padding: 8px 16px;
                a {
                    text-decoration: none;
                    color: #fff;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }
            &:hover {
                background-color: transparent;
            }
        }

    }
}

.footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    font-size: 13px;
    line-height: 23px;
    padding: 16px;
    text-align: center;
    p {
        margin: 0;
    }
}

.main-content {
    background-color: #000;
    .container {
        width: 1160px;
        margin: 0 auto 30px 32px;
        @media (max-width: 1470px) {
            width: 960px;
        }
        @media (max-width: 1270px) {
            width: 760px;
        }
        @media (max-width: 1070px) {
            margin: 0 auto 30px;
        }
        @media (max-width: 839px) {
            width: 450px;
        }
        @media (max-width: 479px) {
            width: 100%;
        }
    }
    .menu-list {
        margin: 0 15px;
        padding: 0;
        width: 100%;
        li {
            display: inline-block;
            padding: 13px 20px 13px 0;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: .04em;
            line-height: 1;
            min-height: 48px;
            -webkit-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
            cursor: default;
            overflow: hidden;
            box-sizing: border-box;
            @media (max-width: 1209px) {
                padding: 13px 15px 13px 0;
            }
            a {
                //color: #37474f;
                color: #fff;
                text-decoration: none;
                box-sizing: border-box;
                display: inline-block;
                margin-bottom: 0;
            }
            ul {
                padding: 0;
                li {
                    padding-bottom: 0;
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
        @media (max-width: 1024px) {
            display: none;
        }
    }
    .content {
        width: 100%;
    }
    .banner-2 {
        width: 100%;
        .teaser {
            height: 90px;
            width: 728px;
            margin: auto;
            @media (max-width: 839px) {
                display: none;
            }
            img {
                max-width: 100%;
                height: auto;
            }
        }

    }
    h2 {
        width: 100%;
        @media (max-width: 839px) {
            margin: 20px 0 15px;
            font-size: 36px;
        }
        @media (max-width: 479px) {
            font-size: 32px;
        }
        &.catalog {
            padding: 0 8px;
        }
    }
    .filter {
        font-size: 0;
        .filter-item {
            display: inline-block;
            select {
                font-family: 'Roboto', 'Helvetica', sans-serif;
                width: 100%;
                padding: 5px 5px;
                font-size: 14px;
                border: none;
                cursor: pointer;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
                height: 30px;
                line-height: 30px;
                background-color: #fff;
            }
        }
    }
    .photo-list {
        font-size: 0;
        .photo-item {
            display: inline-block;
            border-radius: 0;
            min-height: 100px;
            .media {
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                img {
                    width: 100%;
                    margin: 0;
                    height: auto;
                }
            }
            .info-block {
                padding: 8px 0;
                .options {
                    width: 100%;
                    margin-bottom: 8px;
                    padding: 0;
                    position: relative;
                    select {
                        width: 100%;
                        padding: 7px 5px;
                        font-family: 'Roboto', 'Helvetica', sans-serif;
                        -webkit-border-radius: 0;
                        -moz-border-radius: 0;
                        border-radius: 0;
                        height: 30px;
                        border: none;
                        background-color: #fff;
                        cursor: pointer;
                        @media (max-width: 479px) {
                            font-size: 12px;
                        }
                    }
                }
                .action {
                    width: 100%;
                    white-space: nowrap;
                    box-shadow: none;
                    color: #000;
                    padding: 0 5px;
                    margin-bottom: 0;
                    background: #febd0b;
                    height: 36px;
                    line-height: 36px;
                    border-radius: 2px;
                    @media (max-width: 479px) {
                        padding: 0 5px;
                        font-size: 12px;
                    }
                }
            }

        }
    }
    .download {
        .download-buttons {
            line-height: 36px;
            .radio {
                margin-right: 50px;
                .mdl-radio__outer-circle {
                    border-color: #febd0b;
                }
            }
            button {
                float: right;
                width: 260px;
                @media (max-width: 839px) {
                    float: none;
                }
                span {
                    display: inline-block;
                    vertical-align: top;
                }
                .mdl-spinner {
                    margin-top: 4px;
                    margin-right: 14px;
                    .mdl-spinner__layer {
                        border-color: rgb(83, 109, 254) !important;
                    }
                }
            }
            &:after {
                content: '';
                clear: both;
                display: block;
            }
        }
        .download-list {
            margin: 20px 0;
            padding: 0;
            .download-item {
                border: 1px solid rgba(0, 0, 0, 0.12);
                border-bottom: none;
                padding: 0;
                font-size: 0;
                overflow: hidden;
                .box {
                    width: calc(100% - 38px);
                    display: inline-block;
                    font-size: 0;
                    .text {
                        display: inline-block;
                        vertical-align: middle;
                        white-space: nowrap;
                        width: calc(100% - 48px);
                        padding-left: 15px;
                        font-size: 16px;
                    }
                }
                .thumb {
                    width: 48px !important;
                    height: 48px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100%;
                    display: inline-block;
                    vertical-align: middle;
                }
                .mdl-list__item-primary-content {
                    overflow: hidden;
                    .text {
                        white-space: nowrap;
                        display: inline-block;
                    }
                }
                .delete {
                    background-color: #fff;
                    height: 48px;
                    display: inline-block;
                    line-height: 48px;
                    .mdl-button {
                        margin-right: 6px;

                    }
                }
                &:last-child {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                }
            }
        }
    }
    .paginator {
        width: 100%;
        padding: 8px;
        button {
            width: 100%;
            padding: 0 8px;
        }
        .pages {
            text-align: center;
            line-height: 36px;
        }
    }
    .uploads {
        margin: 40px auto 0;
        width: 100%;
        max-width: 730px;
        .filter {
            .mdl-cell-tablet {
                vertical-align: top;
                @media (min-width: 480px) and (max-width: 839px) {
                    width: calc(33.333333333333% - 16px);
                }
            }
            select {
                font-family: 'Roboto', 'Helvetica', sans-serif;
                cursor: pointer;
                border: none;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
                height: 30px;
                line-height: 30px;
                background-color: #fff;
            }
            #add_new_track_form {
                position: relative;
                #new_track_input {
                    padding-right: 25px;
                }
                #track_close {
                    position: absolute;
                    right: 3px;
                    top: 3px;
                    color: #000;
                    cursor: pointer;
                    z-index: 15;
                }
            }
            .Zebra_DatePicker_Icon_Wrapper {
                width: 100% !important;
                position: relative;
            }
            .datepicker, #new_track_input {
                color: #000;
                display: block;
                width: 100%;
                padding: 5px;
                border: none;
                font-family: 'Roboto', 'Helvetica', sans-serif;
                font-size: 14px;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
                border-radius: 0;
                height: 30px;
                line-height: 30px;
                background-color: #fff;
            }
            #track_input {
                font-size: 14px;
                padding-top: 8px;
                line-height: 1;
                cursor: pointer;
            }
        }

        .uploads-field {
            width: calc(100% - 16px);
            min-height: 351px;
            margin: 30px 8px;
            border: 1px dashed #000;
        }
        .upload-button {
            width: calc(100% - 16px);
            margin: 8px;
        }
    }
    .login-content {
        width: 100%;
        max-width: 753px;
        margin: 30px auto 0;
        @media (max-width: 479px) {
            margin-top: 10px;
        }
        h2 {
            width: 100%;
        }
        input[type=text], input[type=password], textarea {
            background-color: #fafafa !important;
            padding-left: 15px;
            padding-right: 15px;
            color: #000;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0 1000px #fafafa inset;
        }
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        textarea:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
            -webkit-box-shadow: 0 0 0 1000px #fafafa inset;
        }
        .label {
            text-align: right;
            span {
                padding-right: 20px;
                line-height: 27px;
                display: inline-block;
            }
        }
        @media (max-width: 479px) {
            .label {
                padding: 8px 8px 0;
                margin: 0;
                text-align: left;
            }
            .name, .pass, .email, .phone, .message {
                padding-top: 0;
                margin: 0 8px 10px;
                .mdl-textfield__label {
                    top: 2px;
                }
            }

        }

        input[type=submit], button {
            width: 100%;
        }
        a {
            text-decoration: none;
            min-width: 1px;
            display: inline;
            padding: 5px;
        }
    }
    .simple_page {
        img {
            max-width: 100%;
            height: auto;
            margin-bottom: 8px;
        }
        p, a {
            font-size: 16px;
            margin-bottom: 16px;
        }
        table {
            margin-bottom: 16px;
            margin-top: 8px;

        }
        ul {
            font-size: 16px;
        }
    }

    .error_message, .errors {
        margin: 8px 0 0;
        list-style: none;
        padding: 0;
        position: absolute;
        li {
            font-size: 12px;
            color: red;
            line-height: 1;
            padding-bottom: 3px;
        }
    }
    .blog {
        .blog_posts {
            list-style-type: none;
            padding: 0;
            margin-top: 24px;
            width: 100%;

            > li {
                padding-bottom: 24px;
                border-bottom: 1px solid #febd0b;
                &:after {
                    display: table;
                    clear: both;
                    content: '';
                }

                .post_title {
                    color: #fff;
                    a {
                        color: #fff;
                        text-decoration: none;
                        font-weight: 400;
                    }
                    small {
                        color: #fff;
                        opacity: 1;
                    }
                    &.first_blog_title {
                        display: none;
                    }
                }
                .post_data {
                    position: relative;
                    .post_image {
                        display: inline-block;
                        float: left;
                        margin: 0 16px 16px 0;
                    }
                    .post_content {
                        &:after {
                            display: table;
                            clear: both;
                            content: '';
                        }
                        .blog_text {
                            margin-bottom: 37px;
                        }
                        p {
                            line-height: 1.5;
                        }
                        ul, ol {
                            list-style-position: inside;
                            padding: 0;
                        }
                    }
                    &:after {
                        display: table;
                        clear: both;
                        content: '';
                    }
                    .post_more {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
                &:first-child {
                    .post_title {
                        display: none;
                        &.first_blog_title {
                            display: block;
                        }
                    }
                    .post_image {
                        float: none;
                        margin: 0;
                        display: block;
                        iframe {
                            width: 1144px !important;
                            height: 815px !important;
                        }
                        @media (max-width: 1470px) {
                            iframe {
                                width: 944px !important;
                                height: 673px !important;
                            }
                        }
                        @media (max-width: 1270px) {
                            iframe {
                                width: 744px !important;
                                height: 530px !important;
                            }
                        }
                        @media (max-width: 839px) {
                            iframe {
                                width: 300px !important;
                                height: 300px !important;
                                margin: auto;
                            }
                        }
                    }
                    .post_content {
                        @media (max-width: 839px) {
                            float: none;
                            width: 100%;
                        }
                    }
                    &.no-img {
                        .post_title, .post_content {
                            float: none;
                            width: 100%;
                        }
                    }
                }
                .banner-blog {
                    margin-top: 24px;
                }
            }
        }
    }
    .post {
        .post_title {
            width: 100%;
            small {
                color: #fff;
                opacity: 1;
            }
        }
        .post_data {
            width: 100%;
            .post_image {
                display: inline-block;
                float: left;
                margin: 0 16px 10px 0;
                @media (max-width: 1270px) {
                    float: none;
                    margin: 0 16px 16px 0;
                }
                @media (max-width: 479px) {
                    float: none;
                    margin: 0 auto 16px;
                    display: block;
                    width: 300px;
                }
            }
            p {
                line-height: 1.5;
            }
            ul, ol {
                list-style-position: inside;
                padding: 0;
            }
        }
    }

}

/**
ADD & REMOVE CART ACTIONS
*/
.processing {
    background: transparent url(/website/static/img/ellipsis.svg) 50% 50% no-repeat !important;
    color: transparent;
    font-size: 0;
}

.added {
    background-color: grey !important;
}

.wrap_drop_uploader {
    display: none;
}

.paypal_empty_error {
    background: #bfdaf1 !important;
}

.post_content {
    .summary {
        font-size: 18px;
        p {
            font-size: 18px;
        }
    }
}

#photo_catalog {
    display: block;
    @media (min-width: 840px) {
        .container {
            width: 92%;
            .content {

                .mdl-cell--3-col {
                    width: calc(20% - 16px);
                }
            }
        }

    }
}