
.image_model {
	display: none;
	position: fixed;
	z-index: 999999999;
	top: 10%;
	width: 80%;
	max-width: 760px;
	max-height: 85vh;
	overflow: auto;
	margin: 0 auto;
	background: #fff;
	border-radius: 2px;
	@media(max-width: 1024px){
		width: 90%;
	}
	@media(max-width: 839px){
		max-width: 450px;
	}

	.mdl-dialog__content {
		padding-top: 0;
	}
	table {
		width: 100%;
		font-size: 12px;
		ul {
			list-style: none;
			font-size: 12px;
			padding: 0;
			margin: 0;
		}
		.hasArray {
			vertical-align: top !important;
		}
	}
	&.active {
		display: block;
	}
}

.image_place {
	width: 100%;
	margin-bottom: 16px;
	img {
		width: 100%;
		max-width:100%;
	}

}